.animated {
    animation-duration: 3s;
    animation-fill-mode: both;
}

@keyframes slideInLeft {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.slideInLeft {
    animation-name: slideInLeft;
}

@keyframes slideInRight {
    from {
        opacity: 0;
        transform: translateX(120px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.slideInRight {
    animation-name: slideInRight;
}

.text-with-shadow {
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
}

/* src/components/AnimatedSection.css */
.animated-section {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.animated-section.animate {
    opacity: 1;
    transform: translateY(0);
}


/* src/components/AnimatedSection.css */
.animated-section {
    opacity: 0;
    transform: translateY(-40px);
    transition: opacity 1.5s ease, transform 1.5s ease;
}

.animated-section.animate {
    opacity: 1;
    transform: translateY(0);
}


/* Add this CSS to your stylesheet */
.hover-content {
    position: relative;
    cursor: pointer;
    transition: transform 0.2s;
}

.hover-content img {
    width: auto;
    height: 30px;
    transition: opacity 0.2s;
}

.hover-content .hover-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 1;
    font-size: 16px;
    color: #5A142A;
    background-color: transparent;
    padding: 4px 8px;
}

.hover-content:hover {
    transform: translateY(-5px);
}

.hover-content:hover img {
    opacity: 0;
}

.hover-content:hover .hover-text {
    opacity: 1;
    visibility: visible;
}

@keyframes zoomAnimation {
        0%, 100% {
            transform: scale(1); /* Normal size */
        }
        50% {
            transform: scale(1.1); /* Zoomed in size */
        }
}

/* For screens with a max-width of 768px (smaller screens) */
    .zoom-text {
        font-size: 60px;
        animation: zoomAnimation 6s infinite alternate;
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
    }

    .zoom-text-alternate {
        font-size: 35px;
        animation: zoomAnimation 6s infinite alternate;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
    }


.animated {
    animation-duration: 0.05s;
    animation-fill-mode: inherit;
}

@keyframes slideInRight {
    from {
        opacity: 0;
        transform: translateX(10px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.slideInRight {
    animation-name: slideInRight;
}

.name-element {
    transition: color 0.1s ease;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
}



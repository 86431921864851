@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(-120px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.project-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
}

.project-card:hover {
    transform: translateY(-20px);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    border-color: #B08D57;
}

.fade-in-up {
    animation: fadeInUp 0.5s ease-out;
}

.animated {
    animation-duration: 10s;
    animation-fill-mode: both;
}


.text-with-shadow {
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
}